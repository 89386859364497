import * as React from "react";
import './about.css'

export default function About() {
  return (
    <div className="content-wrapper">
      <div className="about-us">
        <h2>About Us</h2>
        <p>
          ESDG is an independent association provides an equal opportunity
          platform to its members through uniting people with a finance
          background and other disciplines. This is achieved through networking
          and collaborations in multiple activities such as research, digital
          training, and creating awareness about climate change most especially
          among the youth to empower the future generation to address human
          challenges
        </p>
        <p>
          This is achieved through networking and collaborations in multiple
          activities such as research, digital training, and creating awareness
          about climate change most especially among the youth to empower the
          future generation to address human challenges.
        </p>
        <p>
          The association identifies and helps individuals with special talents
          to maximize their contribution to the world. Its membership is open to
          academicians and those practicing within and outside Uganda. It
          further assists its members to build a common future as closest
          partners through networking and collaboration both internally and
          externally. ESDG Limited envisions sustainable development through
          empowering the human resource with knowledge and skills that are
          needed for productivity and efficient resource utilization by
          individuals, firms, governments, and the World at large. ESDG provides
          a platform to share knowledge and experiences that promote growth. It
          is the first finance association in Uganda to promote cooperation and
          coordination among its stakeholders
        </p>
        <h4>Core Values</h4>
        <ol>
          <li>Professionalism</li>
          <li>Accountabiliy</li>
          <li>Transparency</li>
          <li>Honesty</li>
          <li>Fairness</li>
          <li>Integrity</li>
          <li>Empathy</li>
        </ol>
        <h4>Objectives</h4>
        <ul>
          <li>
            Promotion of harmonization of the education system in the higher
            institutions of learning through influencing curriculum design and
            sustainable capacity building.
          </li>
          <li>
            To provide a platform to experts among key stakeholders in the field
            of finance
          </li>
          <li>
            To ensure that the finance content given to the learners empower
            them with skills for employability
          </li>
          <li>
            Promote research that will help to develop and share knowledge to
            address the challenges to our economy and the World at large.
          </li>
          <li>
            To provide consultancy services in different fields such as cost
            benefit analysis to our stakeholders
          </li>
          <li>
            {" "}
            Assist to strengthen institutions in quality assurance management
          </li>
          <li>
            Partnership with other local and international organizations to
            achieve global goals such as gender equality and inclusivity (GEI),
            poverty reduction, hunger, and address the issue of climate change
            through increasing awareness about environmental protection
            basically among the youth-the future generation.
          </li>
          <li>
            Offer professional advice to the different stakeholders in the
            financial sector
          </li>
          <li>
            To increase access to education especially by the disadvantaged
            groups such as the girl child through lobbing for financial support
            both within and internationally
          </li>
          <li>
            To Increase networking and collaborations with both the local and
            international institutions such as Universities, national council
            for higher education (NCHE) and East Africa University Council,
            Association of African Universities (AAU), and UNESCO.
          </li>
        </ul>
      </div>
    </div>
  );
}
