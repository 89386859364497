import React, { useState } from 'react';
import './navbar.css'

const Navbar = ({team, home, about}) => {

    const [height, setHeight] = useState(22)
    const [isRotated, setIsRotated] = useState(false)

    const HeightChange = () => {
        if (height === 22){ setHeight(95)}
        else setHeight(22)
    }
       
    const Rotate = () => {
        setIsRotated(!isRotated)
    }

    const rotationStyle = {
        transition: 'transform 0.2s ease-in-out',
        transform: isRotated ? 'rotate(45deg)' : 'rotate(0deg)',
    }

    return(
   
        <div className='navbar'>
            <img className='logo' src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`} alt="Logo" />
            <nav>
                <ul style={{ height: height, transition: 'all 0.2s ease-in-out', overflow: 'hidden' }}>
                    <li></li>
                    <li></li>
                    <li onClick={home}>HOME</li>
                    <li onClick={team}>TEAM</li>
                    <li onClick={about}>ABOUT</li>
                </ul>
            </nav>
            <img className='menu-icon' onClick={()=>{HeightChange(); Rotate();}} src={`${process.env.PUBLIC_URL}/assets/icons/menu.svg`} alt="Menu-icon"  style={rotationStyle} />
        </div>)          
}

export default Navbar;
