import * as React from "react";
import "./homeBody.css";

export default function Homebody() {
  let currentBg = (
    <img
      className="bg"
      src={`${process.env.PUBLIC_URL}/assets/images/bg/bg1.webp`}
    ></img>
  );

  return (
    <div>
      <section className="section1">
        <div className="bg-div">
          {currentBg}
          <h1>ESDG</h1>
        </div>

        <div className="content-wrapper">
          <h1>Enhancing Sustainable Development Goals</h1>
          <p></p>
          <div className="primary-section">
            <p>
              <b>Reach US</b> esdglimited@gmail.com +256772301898
            </p>
          </div>
        </div>
      </section>

      <div className="content-wrapper">
        <section className="section2">
          <div className="section2Info">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/consultancy.webp`}
            ></img>
            <h2>Consultancy</h2>
          </div>
          <div className="section2Info">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/quality.webp`}
            ></img>
            <h2>Quality Assessment</h2>
          </div>
          <div className="section2Info">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/others.webp`}
            ></img>
            <h3>Others</h3>
            <ul>
              <li>Research and publications</li>
              <li>Curriculum design</li>
              <li>Employment Link Burea</li>
            </ul>
          </div>
        </section>
      </div>
    </div>
  );
}
