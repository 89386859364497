import * as React from "react";
import './team.css'

export default function Team() {
  return (
    <div>
      <section className="teamSection1">
        <div className="memberArea">
          <img
            className="memberImageSize" alt="member-image"
            src={`${process.env.PUBLIC_URL}/n.jpg`}
          ></img>
          <h3>Nabulya Jesca PhD</h3>
          <h4>Founder | Chairperson Board of Directors</h4>
        </div>
        <div className="memberArea">
          <img
            className="memberImageSize" alt="member-image"
            src={`${process.env.PUBLIC_URL}/assets/images/members/MugerwaPaul.jpg`}
          ></img>
          <h3>Mugerwa Paul (Financial Economist)</h3>
          <h4>Board of Directors member</h4>
        </div>
      </section>
      <section className="teamSection1">
        <div className="memberArea">
          <img
            className="memberImageSize" alt="member-image"
            src={`${process.env.PUBLIC_URL}/assets/images/members/StephenDusengimana.jpg`}
          ></img>
          <h3>Stephen Dusengimana</h3>
          <h4>Executive Secretary</h4>
        </div>
        <div className="memberArea">
          <img
            className="memberImageSize" alt="member-image"
            src={`${process.env.PUBLIC_URL}/assets/images/members/AngelaNamwenje.jpg`}
          ></img>
          <h3>Angela Namwenje</h3>
          <h4>Director of Youth & Child Development</h4>
        </div>
      </section>
    </div>
  );
}
