import * as React from 'react';
import './footer.css'

export default function Footer(){
    return(
        <div className='footer'>
            <small>0772301898</small><hr></hr>
            <small>esdglimited@gmail.com</small>
        </div>
    )
}